import React, { Suspense, useRef, useMemo, useState, useEffect } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrthographicCamera } from "@react-three/drei";
import * as THREE from "three";
import { useSpring, animated, config } from "react-spring";
import AlogoComplete from '../../assets/AlogoComplete.png'
import ButtonCustom from '../ButtonCustom'

function Stars() {
  const ref = useRef<THREE.Points>(null);
  const [sphere] = useState(() => random3DSpherePoints(3000));
  
  const geometry = useMemo(() => {
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(sphere, 3));
    return geometry;
  }, [sphere]);
  
  useFrame((state) => {
    if (ref.current) {
      ref.current.rotation.x = state.clock.getElapsedTime() * 0.1;
      ref.current.rotation.y = state.clock.getElapsedTime() * 0.15;
    }
  });
  
  return (
    <points ref={ref} geometry={geometry}>
      <pointsMaterial size={1} color="#ffff00" sizeAttenuation transparent opacity={0.8} />
    </points>
  );
}

function BackgroundSphere() {
  const meshRef = useRef<THREE.Mesh>(null);
  const [hovered, setHovered] = useState(false);

  useFrame((state) => {
    if (meshRef.current) {
      meshRef.current.rotation.x = state.clock.getElapsedTime() * 0.05;
      meshRef.current.rotation.y = state.clock.getElapsedTime() * 0.075;
    }
  });

  return (
    <mesh
      ref={meshRef}
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
    >
      <sphereGeometry args={[10, 64, 64]} />
      <meshStandardMaterial
        color={hovered ? "#ffff00" : "#222222"}
        wireframe
        transparent
        opacity={0.2}
      />
    </mesh>
  );
}

function random3DSpherePoints(n: number) {
  const points = [];
  for (let i = 0; i < n; i++) {
    const r = 75;
    const theta = 2 * Math.PI * Math.random();
    const phi = Math.acos(2 * Math.random() - 1);
    const x = r * Math.sin(phi) * Math.cos(theta);
    const y = r * Math.sin(phi) * Math.sin(theta);
    const z = r * Math.cos(phi);
    points.push(x, y, z);
  }
  return points;
}

function TextHero() {
  const [isVisible, setIsVisible] = useState(false);
  const heroRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (heroRef.current) {
      observer.observe(heroRef.current);
    }

    return () => {
      if (heroRef.current) {
        observer.unobserve(heroRef.current);
      }
    };
  }, []);

  const fadeIn = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? "translateY(0)" : "translateY(50px)",
    config: config.molasses,
  });

  const handlePlayNowClick = () => {
    window.open("https://t.me/astyletapbot", "_blank", "noopener,noreferrer");
  };

  return (
    <animated.div ref={heroRef} style={fadeIn} className="absolute inset-0 flex items-center justify-center overflow-auto">
      <div className="max-w-4xl w-full mx-auto px-4 text-center py-8">
        <div className="mb-4 sm:mb-6">
          <img 
            src={AlogoComplete}
            alt="A-Style Logo" 
            className="w-28 h-28 sm:w-36 sm:h-36 md:w-44 md:h-44 mx-auto object-contain"
          />
        </div>

        <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-4 sm:mb-6 bg-clip-text text-transparent bg-gradient-to-r from-yellow-400 to-yellow-200">
          A-Style is back!
        </h1>

        <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl mb-6 sm:mb-8 text-gray-300 leading-relaxed mx-2">
          In the early 2000s, 100,000 A-Stickers flooded Italy and Europe. 
          Now your A-Taps can flood the entire globe!  
          Join the exclusive A-STYLE Club with Tap to Earn, level up, and gain your $ASTYLE!  
          Be a part of A-STYLE's grand return!
        </p>

          <ButtonCustom/>
      </div>
    </animated.div>
  );
}

function InsideCanvas() {
  return (
    <>
      <OrthographicCamera makeDefault position={[0, 0, 20]} zoom={5} />
      <Stars />
      <BackgroundSphere />
      <ambientLight intensity={0.5} />
      <directionalLight
        position={[0.0, -5.0, 0]}
        intensity={1}
        castShadow
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
      />
    </>
  );
}

export default function HeroAnimationContainer() {
  return (
    <div className="relative w-full h-screen bg-black overflow-hidden">
      <Suspense fallback={
        <div className="w-full h-full flex items-center justify-center text-white">
          <span className="sr-only">Loading...</span>
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-yellow-400"></div>
        </div>
      }>
        <Canvas
          linear
          shadows
          gl={{
            powerPreference: "high-performance",
            alpha: false,
            antialias: false,
            stencil: false,
          }}
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        >
          <InsideCanvas />
        </Canvas>
      </Suspense>
      <TextHero />
    </div>
  );
}