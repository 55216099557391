import "./styles/App.css";
import Root from "./root";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "./providers/Providers";
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react"

function App() {
  return (
    <>
      <BrowserRouter>
        <Provider>
          <>
            <Root />
          </>
        </Provider>
      </BrowserRouter>
      <Analytics />
      <SpeedInsights/>
    </>
  );
}

export default App;
