import React, { useState, useEffect } from 'react'
import { useSpring, animated, to, config } from '@react-spring/web'

const Button = ({ children, className, ...props }: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <button
    className={`px-4 py-2 bg-yellow-400 text-white font-semibold rounded-md hover:bg-yellow-500 transition-colors duration-200 ${className}`}
    {...props}
  >
    {children}
  </button>
)

const Dot = ({ x, y, size }: { x: number; y: number; size: number }) => {
  const props = useSpring({
    to: async (next) => {
      while (true) {
        await next({ y: y + Math.random() * 10 - 5, size: size * (Math.random() * 0.5 + 0.75) })
        await next({ y: y, size: size })
      }
    },
    from: { y, size },
    config: config.wobbly,
  })

  return (
    <animated.div
      style={{
        width: props.size,
        height: props.size,
        borderRadius: '50%',
        backgroundColor: '#FFD700',
        position: 'absolute',
        opacity: 0.6,
        left: `${x}%`,
        top: props.y.to(y => `${y}%`),
      }}
    />
  )
}

export default function Custom404() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })
  const [dots, setDots] = useState<{ x: number; y: number; size: number }[]>([])

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      setMousePosition({ x: e.clientX, y: e.clientY })
    }

    const generateDots = () => {
      const newDots = []
      const numDots = 35
      for (let i = 0; i < numDots; i++) {
        newDots.push({
          x: Math.random() * 100,
          y: Math.random() * 100,
          size: Math.random() * 15 + 5,
        })
      }
      setDots(newDots)
    }

    window.addEventListener('mousemove', handleMouseMove)
    generateDots()

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  const { x, y } = useSpring({
    x: mousePosition.x / 50,
    y: mousePosition.y / 50,
    config: { mass: 10, tension: 550, friction: 140 },
  })

  const handleBackToHome = () => {
    window.location.href = '/'
  }

  return (
    <div className="relative min-h-screen bg-black flex flex-col justify-center items-center px-4 sm:px-6 lg:px-8 overflow-hidden">
      {dots.map((dot, index) => (
        <Dot 
          key={index} 
          x={dot.x}
          y={dot.y}
          size={dot.size} 
        />
      ))}

      <animated.div 
        style={{ 
          transform: to([x, y], (x, y) => `translate3d(${x * 2}px, ${y * 2}px, 0)`)
        }}
        className="absolute inset-0 flex items-center justify-center"
      >
        <div className="text-yellow-400 text-opacity-30 text-[20rem] font-extrabold">404</div>
      </animated.div>

      <div className="relative z-10 max-w-md w-full space-y-8 text-center bg-gray-900 bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-xl p-8">
        <div>
          <h1 className="text-6xl font-extrabold text-yellow-400">404</h1>
          <h2 className="mt-2 text-3xl font-bold text-yellow-200">Page Not Found</h2>
          <p className="mt-2 text-sm text-gray-300">
            Sorry, we couldn't find the page you're looking for.
          </p>
        </div>
        <div className="mt-5">
          <Button className="w-full" onClick={handleBackToHome}>
            <h1 className='text-black'>Back to Home</h1>
          </Button>
        </div>
      </div>
    </div>
  )
}