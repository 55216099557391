import React, { useState, useEffect, useRef } from 'react'
import { useSpring, animated } from 'react-spring'
import ParallaxText from './ParallaxText'
import ButtonCustom from './ButtonCustom'
import prs1 from "../assets/bgHat.png"

export default function FullscreenHeroSection() {
  const [isVisible, setIsVisible] = useState(false)
  const sectionRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true)
          observer.unobserve(entry.target)
        }
      },
      {
        threshold: 0.1 // Trigger when 10% of the section is visible
      }
    )

    if (sectionRef.current) {
      observer.observe(sectionRef.current)
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current)
      }
    }
  }, [])

  const fadeIn = useSpring({
    opacity: isVisible ? 1 : 0,
    config: { duration: 2000 },
  })

  const slideUp = useSpring({
    transform: isVisible ? 'translateY(0)' : 'translateY(50px)',
    opacity: isVisible ? 1 : 0,
    config: { tension: 280, friction: 60 },
  })

  return (
    <animated.section 
      ref={sectionRef}
      style={fadeIn} 
      className="flex items-center justify-center min-h-screen w-full overflow-hidden relative"
    >
      <div className="container mx-auto px-4 z-10 flex flex-col lg:flex-row items-center justify-between max-sm:mt-44 mt-24">
        <ParallaxText className="max-w-3xl lg:w-1/2 lg:pr-8">
          <animated.h1 style={slideUp} className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-4 sm:mb-6 bg-clip-text text-transparent bg-gradient-to-r from-yellow-400 to-yellow-200">
            Tap to Earn: Revolutionize Your A-STYLE Experience!
          </animated.h1>
          <animated.p style={slideUp} className="text-lg sm:text-xl md:text-2xl lg:text-3xl mb-6 sm:mb-8 text-gray-300 leading-relaxed">
            Contribute to spreading the A-STYLE spirit while earning Tokens.  
            Leverage the technology, security, and privacy of our Miniapp format - no downloads required on your device. (Telegram is needed)
            Collect, trade, and unlock VIP benefits in the A-STYLE Club.  
            Hold onto your $ASTYLE; they will drive A-STYLE's return to the Fashion Universe.
          </animated.p>
          <animated.div style={slideUp}>
            <ButtonCustom />
          </animated.div>
        </ParallaxText>
        <animated.div style={slideUp} className="lg:w-1/2 mt-8 lg:mt-0">
          <img src={prs1} alt="A-STYLE Hat" className="w-full h-auto max-w-md mx-auto max-sm:scale-90" />
        </animated.div>
      </div>
    </animated.section>
  )
}