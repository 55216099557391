'use client'

import React from 'react'
import { useSpring, animated, config } from 'react-spring'
import { useScroll } from 'react-use-gesture'

const GlowingBackground = () => {
  const [{ scroll }, set] = useSpring(() => ({ scroll: 0 }))

  const bind = useScroll(event => {
    set({ scroll: event.scrolling ? event.xy[1] : 0 })
  })

  const glow = useSpring({
    background: scroll.to(
      s => `radial-gradient(circle at ${50 + s / 50}% ${50 + s / 100}%, rgba(255, 215, 0, 0.15), transparent 80%)`
    ),
    config: config.molasses,
  })

  return <animated.div {...bind()} style={glow} className="fixed inset-0 pointer-events-none" />
}

export default GlowingBackground