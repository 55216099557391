'use client'

import React from 'react'
import { useSpring, animated } from 'react-spring'
import { useScroll } from 'react-use-gesture'

const ParallaxDots = ({ count = 150 }) => {
  const dots = Array.from({ length: count }, (_, i) => ({
    id: i,
    x: Math.random() * 100,
    y: Math.random() * 100,
    size: Math.random() * 3 + 1,
  }))

  const [{ scroll }, set] = useSpring(() => ({ scroll: 0 }))

  const bind = useScroll(event => {
    set({ scroll: event.scrolling ? event.xy[1] : 0 })
  })

  return (
    <div {...bind()} className="fixed inset-0 pointer-events-none">
      {dots.map(dot => (
        <animated.div
          key={dot.id}
          style={{
            position: 'absolute',
            left: `${dot.x}%`,
            top: `${dot.y}%`,
            width: dot.size,
            height: dot.size,
            borderRadius: '50%',
            backgroundColor: 'rgba(255, 215, 0, 0.2)',
            transform: scroll.to(s => `translate3d(0, ${Math.sin(s / 100 + dot.id) * 20}px, 0)`),
          }}
        />
      ))}
    </div>
  )
}

export default ParallaxDots