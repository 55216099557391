'use client'

import React from 'react'
import { useSpring, animated, config } from 'react-spring'
import { useScroll } from 'react-use-gesture'

const ParallaxText = ({ children, className = '' } : any) => {
  const [{ scroll }, set] = useSpring(() => ({ scroll: 0 }))

  const bind = useScroll(event => {
    set({ scroll: event.scrolling ? event.xy[1] : 0 })
  })

  const parallaxProps = useSpring({
    transform: scroll.to(s => `translate3d(0, ${s / 20}px, 0)`),
    config: config.slow,
  })

  return (
    <animated.div {...bind()} style={parallaxProps} className={className}>
      {children}
    </animated.div>
  )
}

export default ParallaxText