import {useEffect} from "react";
import { SearchAndCopy } from "../components/landing/SearchAndCopy";
import { CertifiedHistory } from "../components/landing/CertifiedHistory";

import "../styles/scss/general.scss";
import VideoPlayer from "../components/landing/VideoPlayer";
import Roadmap from "../components/Roadmap";
import Step from "../components/Step";
import { TheBrand } from "../components/landing/theBrand";
import { Footer } from "../components/landing/Footer";
import { Box } from "../components/MUI/MuiBox";
import HeroAnimationContainer from "../components/animation/HeroAnimationContainer";
import NavebarGlass from "../components/NavebarGlass";
import { useState } from "react";
import { useLocation } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';
import TapToEarnModal from "../components/Modal/AStyleTapToEarnModal";
import BannerProduct from "../components/landing/bannerProduct";
import { T2E } from "../components/landing/T2E";
import GlowingBackground from "../components/GlowingBackground";
import ParallaxDots from "../components/ParallaxDots";
import HeroSection from "../components/HeroSection";
import NFCSection from "../components/NFCSection";
import RoadmapSection from "../components/RoadmapSection";
import FloatingImage from "../components/FloatingImage";
import FAQSection from "../components/FAQSection";

export const Landing = () => {
	const location = useLocation();
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		setIsModalOpen(true)
	  }, [])

	useEffect(() => {
	  if (location.hash) {
		const targetId = location.hash.replace('#', '');
		scroller.scrollTo(targetId, {
		  duration: 500,
		  delay: 0,
		  smooth: true,
		  offset: 50,
		});
	  }
	}, [location]);
	return (

		
		<>
			<div className="relative">
			{/*<TapToEarnModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />*/}
				<NavebarGlass />
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<HeroAnimationContainer />
					<div style={{ zIndex: 1001 }} className="-mt-36">
						<GlowingBackground/>
						<ParallaxDots count={100}/>
						<HeroSection/>
						{/*<FloatingImage/>*/}
						{/*<NFCSection/>*/}
						{/*<RoadmapSection/>*/}
						{/*<SearchAndCopy />*/}	
						{/*<CertifiedHistory />*/}
						{/*<VideoPlayer />*/}
						{/*<Step />*/}
						{/*<TheBrand />*/}
						{/*<BannerProduct/>*/}
						<Footer />
					</div>
				</Box>
			</div>
		</>
	);
};
