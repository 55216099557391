"use client"

import React, { useState, useEffect } from 'react'
import { motion, useAnimationControls } from 'framer-motion'

export default function EnhancedButton() {
  const [isHovered, setIsHovered] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const borderControls = useAnimationControls()
  const glowControls = useAnimationControls()

  const handleClick = () => {
    setIsClicked(true)
    setTimeout(() => setIsClicked(false), 1000)
  }

  useEffect(() => {
    if (isHovered) {
      borderControls.start({
        pathLength: [0, 1],
        opacity: [0, 1],
        transition: { duration: 0.5, ease: "easeInOut" }
      })
      glowControls.start({
        scale: [1, 1.1, 1],
        opacity: [0.6, 0.8, 0.6],
        transition: { duration: 1.5, repeat: Infinity, ease: "easeInOut" }
      })
    } else {
      borderControls.start({
        pathLength: 0,
        opacity: 0,
        transition: { duration: 0.3 }
      })
      glowControls.start({
        scale: 1,
        opacity: 0.6,
        transition: { duration: 0.3 }
      })
    }
  }, [isHovered, borderControls, glowControls])

  return (
    <div className='max-sm:flex max-sm:justify-center max-sm:scale-90'>
      <motion.div
        className="relative inline-block"
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
      >
        <motion.div
          className="absolute inset-0 bg-yellow-300 rounded-full blur-xl"
          animate={glowControls}
          style={{ transform: 'scale(1.1)' }}
        />
        <div className="scale-[1] max-[400px]:scale-90">
          <div className="absolute -inset-4 bg-gradient-to-r from-yellow-600 to-yellow-800 rounded-full opacity-75 overflow-hidden">
            <motion.div
              className="w-1/4 h-full bg-white blur-sm"
              animate={{
                x: ['-100%', '400%'],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "linear",
              }}
              style={{
                clipPath: 'polygon(0 0, 100% 0, 75% 100%, 25% 100%)',
              }}
            />
          </div>
          <a 
            href="https://t.me/astyletapbot" 
            className='relative z-10'
            target="_blank"
            rel="noopener noreferrer"
          >
            <motion.button
              className="relative px-20 py-6 text-2xl font-bold text-gray-900 bg-gradient-to-r from-yellow-300 to-yellow-400 rounded-full overflow-hidden"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleClick}
            >
              Play Now!
              <motion.svg
                className="absolute inset-0 w-full h-full"
                viewBox="0 0 100 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <motion.path
                  d="M0,50 a50,50 0 1,0 100,0 a50,50 0 1,0 -100,0"
                  stroke="rgba(255,255,255,0.5)"
                  strokeWidth="4"
                  strokeDasharray="0 1"
                  animate={borderControls}
                />
              </motion.svg>
            </motion.button>
          </a>
        </div>
        {isClicked && (
          <motion.div
            className="absolute top-0 left-0 w-full h-full"
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: [0, 1, 0], scale: [0.8, 1.2, 1.5] }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{ duration: 0.5 }}
          >
            {[...Array(12)].map((_, index) => (
              <motion.div
                key={index}
                className="absolute w-3 h-3 bg-yellow-400 rounded-full"
                initial={{ 
                  x: '50%', 
                  y: '50%',
                }}
                animate={{ 
                  x: `${50 + 70 * Math.cos(index * Math.PI / 6)}%`,
                  y: `${50 + 70 * Math.sin(index * Math.PI / 6)}%`,
                  opacity: [1, 0],
                }}
                transition={{
                  duration: 0.5,
                  ease: "easeOut",
                }}
              />
            ))}
          </motion.div>
        )}
      </motion.div>
    </div>
  )
}
