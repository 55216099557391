'use client'

import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useScroll, useTransform, AnimatePresence } from 'framer-motion';
import { Element, animateScroll as scroll, Link } from 'react-scroll';
import shuffle from 'lodash.shuffle';

import { ADVpalo, IMG_5093, IMG_5109, IMG_5114, IMG_5128, IMG_5131, IMG_5132, IMG_5135, IMG_5136, IMG_5138, IMG_5139, IMG_5140, IMG_5150, IMG_5151, logo, main1, main2, main3 } from '../historyPics/index';
import NavebarGlass from '../components/NavebarGlass';
import { Footer } from '../components/landing/Footer';
import GlowingBackground from '../components/GlowingBackground';
import ParallaxDots from '../components/ParallaxDots';

const images = [
  ADVpalo, IMG_5093, IMG_5109, IMG_5114, IMG_5128, IMG_5131,
  IMG_5132, IMG_5135, IMG_5136, IMG_5138, IMG_5139, 
  IMG_5140, IMG_5150, IMG_5151, logo,
];

const LoadingAnimation = () => (
  <motion.div
    className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    <motion.div
      className="w-16 h-16 border-4 border-yellow-400 border-t-transparent rounded-full"
      animate={{ rotate: 360 }}
      transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
    />
  </motion.div>
);

export default function Component() {
  const [loading, setLoading] = useState(true);
  const [introRef, introInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const [historyRef, historyInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const [galleryRef, galleryInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [items, setItems] = useState(images);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const { scrollY } = useScroll();
  const y1 = useTransform(scrollY, [0, 1000], [0, 500]);
  const opacity = useTransform(scrollY, [0, 500], [1, 0]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setItems(shuffle);
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const loadImages = async () => {
      const imagePromises = images.map(src => {
        return new Promise<void>((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = () => resolve();
          img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
        });
      });

      try {
        await Promise.all(imagePromises);
        setLoading(false);
      } catch (error) {
        console.error('Failed to load images:', error);
        setLoading(false);
      }
    };

    loadImages();
  }, []);

  useEffect(() => {
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    const style = document.createElement('style');
    style.textContent = `
      body {
        font-family: 'Montserrat', sans-serif;
        background-color: #000;
        color: #FFD700;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(link);
      document.head.removeChild(style);
    };
  }, []);

  const openPopup = (image: string) => {
    setSelectedImage(image);
  };

  const closePopup = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      <GlowingBackground/>
      <ParallaxDots count={100}/>
      <NavebarGlass />
      {loading && <LoadingAnimation />}
      <div className="overflow-hidden">
        <Element name="intro">
          <section ref={introRef} className="relative h-screen flex items-center justify-center">
            <motion.div style={{ y: y1, opacity }} className="absolute inset-0">
              <img src={main1} alt="A-Style Background" className="w-full h-full object-cover opacity-50" />
            </motion.div>
            <div className="relative z-10 text-center px-4">
              <h1 className="text-6xl font-bold mb-8">Incredible Story</h1>
              <p className="text-xl max-w-3xl mx-auto">
                A-style was born in the '90s at the hands of Marco Bruns: two simple dots transformed the first letter of the alphabet into a unique and recognizable logo. The explosive power of a bold but never offensive message.
              </p>
            </div>
          </section>
        </Element>

        <Element name="history">  
          <section
            ref={historyRef}
            className="bg-black text-yellow-400 py-16 px-4 sm:px-6 lg:px-8 relative min-h-screen flex items-center"
          >
            <ParallaxDots count={100}/>
            <div className="max-w-7xl mx-auto">
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={historyInView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.8, ease: "easeOut" }}
                className="text-center"
              >
                <h2 className="text-6xl font-bold mb-8">Our History</h2>
                <p className="mt-4 max-w-3xl text-xl mx-auto mb-12">
                The idea itself would not have had the same impact if it hadn’t been accompanied by a brilliant marketing strategy. Just a few hundred euros were spent on thousands of stickers that were put up at night on traffic light poles around Milan. The sudden appearance of A-style, shrouded in mystery and anonymity, immediately captured the attention of the city’s residents and the media in the fashion capital. The A-style logo became famous long before the product it would later accompany.</p>
                <p className="mt-4 max-w-3xl text-xl mx-auto mb-12">
                  In the early 2000s, Marco Bruns revealed himself as the man behind A-style and began producing a line of t-shirts entirely made in Milan. Sales exploded immediately, and the logo's popularity grew with the commercialization of products that became iconic in 2000s fashion.
                </p>
              </motion.div>
              <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                {[
                  { year: '1999', text: 'A-Style is founded in Italy', image: main1 },
                  { year: '2004', text: 'Expansion into international markets', image: main2 },
                  { year: '2010', text: 'Launch of our iconic logo collection', image: main3 },
                ].map((item, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={historyInView ? { opacity: 1, scale: 1 } : {}}
                    transition={{ duration: 0.5, delay: index * 0.2 }}
                    className="flex flex-col items-center"
                  >
                    <div className="relative w-full h-64 mb-4 overflow-hidden rounded-lg shadow-lg">
                      <img
                        src={item.image}
                        alt={`A-Style in ${item.year}`}
                        className="object-cover w-full h-full"
                      />
                    </div>
                    <h3 className="text-2xl font-bold mb-2">{item.year}</h3>
                    <p className="text-lg text-center">{item.text}</p>
                  </motion.div>
                ))}
              </div>
            </div>
          </section>
        </Element>

        <Element name="gallery">
          <section
            ref={galleryRef}
            className="bg-yellow-400 py-16 px-4 sm:px-6 lg:px-8 relative min-h-screen flex items-center"
          >
            <div className="max-w-7xl mx-auto relative z-10">
              <h2 className="text-6xl font-bold text-black mb-12 text-center">Our Gallery</h2>
              <p className="text-xl text-black max-w-3xl mx-auto mb-12 text-center">
                The logo became a true brand, and its communication strategy evolved. It was no longer just guerrilla marketing but also traditional marketing, which, with a brand like A-style, became disruptive even in the most institutional appearances, endorsements, sponsorships, and trade shows.
              </p>
              <p className="text-xl text-black max-w-3xl mx-auto mb-12 text-center">
                The product matured and diversified, as did its distribution. Quality and design, but above all, that symbol: as simple as it was powerful. The logo was too attractive not to be copied, and A-style achieved an unusual distinction: more counterfeit products were in circulation than the originals, often appearing in places and countries never touched by the official distribution.
              </p>
              <p className='text-xl text-black max-w-3xl mx-auto mb-12 text-center'>
                A-style was not born as a brand or a commercial enterprise: passion and rebellion, freedom and independence - First, the symbol was born; then it became a brand.
              </p>
              <div className="grid grid-cols-5 gap-4">
                <AnimatePresence>
                  {items.map((item, index) => (
                    <motion.div
                      key={item}
                      layout
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.8 }}
                      transition={{ duration: 0.5 }}
                      className="aspect-[3/4] rounded-lg overflow-hidden shadow-lg cursor-pointer relative"
                      whileHover={{ scale: 1.05 }}
                      onClick={() => openPopup(item)}
                    >
                      <img
                        src={item}
                        alt={`Gallery image ${index + 1}`}
                        className="w-full h-full object-cover"
                      />
                      <motion.div
                        className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center"
                        initial={{ opacity: 0 }}
                        whileHover={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                      >
                        <span className="text-white text-lg font-bold">View</span>
                      </motion.div>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </div>
            </div>
          </section>
        </Element>

        {selectedImage && (
          <motion.div 
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closePopup}
          >
            <motion.div 
              className="max-w-3xl max-h-full p-4"
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
            >
              <img 
                src={selectedImage} 
                alt="Selected gallery image" 
                className="max-w-full max-h-full object-contain rounded-lg shadow-2xl"
              />
            </motion.div>
          </motion.div>
        )}

        <Link
          to="intro"
          smooth={true}
          duration={800}
          className="fixed bottom-4 right-4 bg-yellow-400 text-black p-2 rounded-full shadow-lg hover:bg-yellow-300 transition-colors duration-300 cursor-pointer"
          aria-label="Scroll to top"
        >
          ↑
        </Link>
      </div>
      <Footer />
    </div>
  );
}